import { Button, Stack, Typography } from '@mui/material';
import React from 'react';
import TextField from 'src/components/@admin/TextField';
import { useRequestPasswordResetMutation } from 'src/graphql/generated/graphql';
import useNotification from 'src/hooks/useNotification';

function ForgotPassword({ setStatus }) {
   const [email, setEmail] = React.useState('');
   const [_msg, sendNotification] = useNotification();

   const [requestPasswordReset] = useRequestPasswordResetMutation({
      onCompleted: () => {
         setStatus('LOGIN');
      },
      onError: (error) => {
         sendNotification({
            msg: error.message,
            variant: 'error',
         });
      },
   })

   const handleSubmit = async () => {
      try {
         if (email.length === 0) {
            sendNotification({
               msg: 'Digite um email válido',
               variant: 'error',
            });

            return;
         }

         requestPasswordReset({
            variables: {
               email,
            },
         });

         sendNotification({
            msg: 'Código enviado com sucesso',
            variant: 'success',
         });
      } catch (error) {
         sendNotification({
            msg: error.message,
            variant: 'error',
         });
      }
   };

   const handleRetorn = () => {
      setStatus('LOGIN');
   };

   return (
      <Stack>
         <Stack gap={1}>
            <Typography variant="h3">Esqueceu sua senha?</Typography>
            <Typography variant="body2" color="GrayText">
               Digite seu e-mail de cadastro para receber um código de segurança e recuperar sua conta.
            </Typography>
         </Stack>
         <Stack gap={1} mt={2}>
            <TextField
               label="Email"
               required
               type="email"
               value={email}
               onChange={(e) => setEmail(e.target.value)}
               placeholder="Digite seu email"
            />
         </Stack>

         <Stack gap={2} mt={4}>
            <Button sx={{ fontSize: '1rem', height: 54 }} variant={'contained'} size="large" onClick={handleSubmit}>
               Enviar
            </Button>
            <Stack alignItems="center">
               <Button variant={'text'} onClick={handleRetorn}>
                  Voltar
               </Button>
            </Stack>
         </Stack>
      </Stack>
   );
}

export default ForgotPassword;
