import React from 'react';
import { FormLabel, Stack, TextField as TextFieldMUI, TextFieldProps as TextFieldPropsMUI } from '@mui/material';

export type TextFieldProps = TextFieldPropsMUI & {
   iconSize?: number;
   iconColor?: string;
   required?: boolean;
   multiline?: boolean;
   maxRows?: number;
   register?: any;
   errorMessage?: any;
};

const TextField: React.FC<TextFieldProps> = ({ label, register, errorMessage, ...props }) => {
   return (
      <Stack gap={0.5} width="100%" sx={{ ...props.sx }}>
         {label && (
            <FormLabel>
               {label} {!props.required && '(opcional)'}
            </FormLabel>
         )}
         <TextFieldMUI fullWidth {...props} {...register} helperText={errorMessage} />
      </Stack>
   );
};

export default TextField;
