import { useContext, useEffect, useState } from 'react';

import {
   Container,
   Stack,
   Typography,
   useMediaQuery,
   Modal,
   Button,
   Divider,
   IconButton,
   SwipeableDrawer,
   useTheme,
} from '@mui/material';

import { ContainerHeader } from './styles';

import Link from 'next/link';
import { Logo } from 'src/components/svgs/Logo';
import useNotification from 'src/hooks/useNotification';
import { useRouter, useSearchParams } from 'next/navigation';
import { MobileMediaQuery } from 'src/utils/constants';
import { AccountCircleOutlined } from '@mui/icons-material';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import { UserContext } from 'src/contexts/user';
import TextFieldPassword from 'src/components/@admin/TextFieldPassword';
import TextField from 'src/components/@admin/TextField';
import Login from './status/login';
import ForgotPassword from './status/forgotPassword';

type STATUS = 'LOGIN' | 'FORGOT_PASSWORD';

const ComponentStatus = {
   LOGIN: (props) => <Login setStatus={props.setStatus} />,
   FORGOT_PASSWORD: (props) => <ForgotPassword setStatus={props.setStatus} />,
};

export default function Header() {
   const router = useRouter();
   const isMobile = useMediaQuery(MobileMediaQuery);
   const searchParams = useSearchParams();
   const [status, setStatus] = useState<STATUS>('LOGIN');

   const { submitForm } = useContext(UserContext);
   const { palette } = useTheme();

   const [isMenuOpen, setIsMenuOpen] = useState(false);
   const [username, setUsername] = useState('');
   const [password, setPassword] = useState('');
   const [_msg, sendNotification] = useNotification();
   const handleMenuToggle = () => {
      setIsMenuOpen((prevIsMenuOpen) => !prevIsMenuOpen);
   };

   const handleRegisterClick = () => {
      router.push('/register');
   };

   const handleCloseModal = () => {
      router.push('?');
   };

   const handleLoginClick = () => {
      router.push('?login=true');
   };

   const isLogin = searchParams.get('login');
   const [loginModalOpen, setLoginModalOpen] = useState<boolean>();

   useEffect(() => {
      setLoginModalOpen(Boolean(isLogin));
   }, [isLogin]);

   const handleLogin = async () => {
      await submitForm({
         variables: {
            loginEmail: username,
            password: password,
         },
      });
   };

   return (
      <>
         <ContainerHeader
            sx={{
               zIndex: 10,
            }}
         >
            <Container sx={{ height: '100%' }}>
               <Stack
                  direction="row"
                  sx={{ width: '100%', justifyContent: 'space-between', alignItems: 'center', height: '100%' }}
               >
                  {isMobile && (
                     <IconButton size="large" onClick={handleMenuToggle}>
                        <MenuOutlinedIcon color="primary" />
                     </IconButton>
                  )}

                  <Link href="/">
                     <Logo />
                  </Link>

                  {isMobile ? (
                     <IconButton size="large" onClick={handleLoginClick}>
                        <AccountCircleOutlined color="primary" />
                     </IconButton>
                  ) : (
                     <Stack direction="row" gap={4} sx={{ alignItems: 'center' }}>
                        <Link href="/#sobre_nos">Sobre nós</Link>
                        <Link href="/#sobre_nos">Pesquisar</Link>

                        <Stack direction="row" gap={2}>
                           <Button size="large" variant="outlined" onClick={handleRegisterClick}>
                              Cadastre-se
                           </Button>
                           <Divider orientation="vertical" flexItem />
                           <Button
                              size="large"
                              startIcon={<AccountCircleOutlined />}
                              variant="contained"
                              type="submit"
                              onClick={handleLoginClick}
                           >
                              Entrar
                           </Button>
                        </Stack>
                     </Stack>
                  )}
               </Stack>
            </Container>
         </ContainerHeader>
         <Modal open={Boolean(loginModalOpen)} onClose={handleCloseModal}>
            <Stack
               sx={{
                  backgroundColor: 'white',
                  padding: '2rem',
                  borderRadius: '0.5rem',
                  gap: '1rem',
                  position: 'absolute' as 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: '100%',
                  maxWidth: 500,
               }}
            >
               {ComponentStatus[status]({ setStatus })}
            </Stack>
         </Modal>
         {isMobile && (
            <SwipeableDrawer
               sx={{
                  '& .MuiDrawer-paper': {
                     borderRadius: '0 1rem 1rem 0',
                     background: palette.primary.main,
                  },
               }}
               open={isMenuOpen}
               onOpen={handleMenuToggle}
               onClose={handleMenuToggle}
               anchor="left"
            >
               <Stack
                  sx={{
                     width: 300,
                     backgroundColor: 'white',
                     padding: '2rem',
                     margin: '0.5rem',
                     borderRadius: '1rem',
                  }}
               >
                  <Logo />

                  <Stack gap={4} mt={8}>
                     <Link href="/#sobre_nos">Sobre nós</Link>
                     <Link href="/#sobre_nos">Pesquisar</Link>

                     <Stack gap={2}>
                        <Button size="large" variant="outlined" onClick={handleRegisterClick}>
                           Cadastre-se
                        </Button>
                        <Divider />
                        <Button
                           size="large"
                           startIcon={<AccountCircleOutlined />}
                           variant="contained"
                           type="submit"
                           onClick={handleLoginClick}
                        >
                           Entrar
                        </Button>
                     </Stack>
                  </Stack>
               </Stack>
            </SwipeableDrawer>
         )}
      </>
   );
}
