import { styled } from "@mui/material";

export const ContainerHeader = styled("header")(({ theme }) => ({
    width: "100%",
    display: "flex",
    flexDirection: "column",
    position: "fixed",
    backgroundColor: "white",
    top: 0,
    height: 100, 
    overflowY: "auto", 
    zIndex: 10,
    boxShadow: "0px 0px 10px 10px #00000020"
}));
