import React, { useState } from 'react';
import { FormLabel, IconButton, InputAdornment, OutlinedInput, Stack, TextField as TextFieldMUI, TextFieldProps as TextFieldPropsMUI } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

export type TextFieldProps = TextFieldPropsMUI & {
  iconSize?: number;
  iconColor?: string;
  required?: boolean;
  multiline?: boolean
  maxRows?: number
  register?: any
  errorMessage?: any
}

const TextFieldPassword: React.FC<TextFieldProps> = ({
  label,
  register,
  errorMessage,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <Stack gap={.5} width="100%">
      {label && <FormLabel>{label}</FormLabel>}
      <OutlinedInput
        fullWidth
        {...props}
        {...register}
        helperText={errorMessage}
        type={showPassword ? 'text' : 'password'}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />
    </Stack>
  );
};

export default TextFieldPassword  