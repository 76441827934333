import { Button, Stack, Typography } from '@mui/material';
import { useRouter, useSearchParams } from 'next/navigation';
import React, { useContext, useState } from 'react';
import TextFieldPassword from 'src/components/@admin/TextFieldPassword';
import TextField from 'src/components/@admin/TextField';
import { UserContext } from 'src/contexts/user';

function Login({ setStatus }: { setStatus: (status: 'LOGIN' | 'FORGOT_PASSWORD') => void }) {
   const router = useRouter();
   const searchParams = useSearchParams();

   const { submitForm } = useContext(UserContext);

   const [username, setUsername] = useState('');
   const [password, setPassword] = useState('');

   const handleRegisterClick = () => {
      router.push('/register');
   };

   const handleLogin = async () => {
      await submitForm({
         variables: {
            loginEmail: username,
            password: password,
         },
      });
   };

   return (
      <Stack>
         <Stack gap={1}>
            <Typography variant="h3">Entrar</Typography>
            <Typography>Bem-vindo de volta!</Typography>
         </Stack>
         <Stack gap={1} mt={2}>
            <TextField
               label="Email"
               required
               type="email"
               value={username}
               onChange={(e) => setUsername(e.target.value)}
               onKeyDown={(e) => {
                  if (e.key === 'Enter') handleLogin();
               }}
               placeholder="Digite seu email"
            />
            <TextFieldPassword
               type="password"
               label="Senha"
               value={password}
               onChange={(e) => setPassword(e.target.value)}
               onKeyDown={(e) => {
                  if (e.key === 'Enter') handleLogin();
               }}
            />
         </Stack>
         <Stack gap={2} mt={2}>
            <Button
               sx={{ fontSize: '1rem', height: 54 }}
               type="submit"
               variant={'contained'}
               size="large"
               onClick={handleLogin}
            >
               Entrar
            </Button>
            <Button variant={'outlined'} size="large" onClick={handleRegisterClick}>
               Cadastre-se
            </Button>
            <Stack alignItems="center">
               <Button variant={'text'} onClick={() => setStatus('FORGOT_PASSWORD')}>
                  Esqueceu a senha?
               </Button>
            </Stack>
         </Stack>
      </Stack>
   );
}

export default Login;
