import { Container, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import Link from 'next/link';
import React from 'react'
import { Logo } from 'src/components/svgs/Logo';
import { MobileMediaQuery } from 'src/utils/constants';

export default function Footer() {
  const { palette } = useTheme()
  const isMobile = useMediaQuery(MobileMediaQuery);
  const year = new Date().getFullYear()

  return (
    <>
      <Stack sx={{
        backgroundColor: palette.primary.main,
        color: palette.primary.contrastText,
        padding: 4,
        height: isMobile ? 240 : 200,
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <Container>
          <Stack direction={isMobile ? "column" : "row"} sx={{ justifyContent: "space-between", gap: 6 }}>
            <Logo color="#ffffff" />

            <Stack gap={2}>
              <Link href="/#sobre_nos">
                Sobre nós
              </Link>
              <Link href="/#sobre_nos">
                Pesquisar
              </Link>
              <Link href="/register">
                Cadastre-se
              </Link>
            </Stack>
          </Stack>
        </Container>
      </Stack>
      <Stack
        sx={{
          width: "100vw",
          padding: 2,
          alignItems: "center",
          justifyContent: "center",
          background: "black",
          color: "white",
        }}
      >
        <Typography variant="body2" textAlign="center">Copyright © {year} AlugueTotal. Todos os direitos reservados.</Typography>
      </Stack>
    </>
  )
}
